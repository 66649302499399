<template>
  <v-card flat class="fill-height d-flex flex-column">
    <v-card-title class="pa-0">
      <v-tabs
        background-color="grey lighten-4"
        :disabled="loading || processing"
        mandatory
        slider-color="primary"
        v-model="tab"
        height="56"
      >
        <v-tab class="body-2 font-weight-bold text-capitalize" href="#leave">
          <v-badge
            color="green"
            :value="dashboardData.leaveRequests.length"
            :content="dashboardData.leaveRequests.length"
          >
            <v-icon size="18" left>tw-hr-travel</v-icon>
            {{ $t(`dashboard.request_type.leave`) }}
          </v-badge>
        </v-tab>
        <v-tab class="body-2 font-weight-bold text-capitalize" href="#overtime">
          <v-badge
            color="green"
            :value="dashboardData.overtimeRequests.length"
            :content="dashboardData.overtimeRequests.length"
          >
            <v-icon size="18" left>tw-shifts</v-icon>
            {{ $t(`dashboard.request_type.overtime`) }}
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="pa-0 flex-grow-1">
      <v-tabs-items v-model="tab" mandatory class="fill-height">
        <v-tab-item value="leave" class="fill-height">
          <template v-if="loading">
            <v-card class="elevation-0 fill-height d-flex justify-center align-center">
              <v-progress-circular indeterminate size="60" color="grey lighten-3" />
            </v-card>
          </template>
          <template v-else>
            <v-data-table
              v-if="dashboardData.leaveRequests.length"
              class="request-table"
              :items="dashboardData.leaveRequests"
              :loading="loading"
              :no-data-text="loading ? '' : $vuetify.noDataText"
              :footer-props="{ itemsPerPageOptions: [5] }"
              hide-default-header
            >
              <template #item="row">
                <tr>
                  <td>
                    <employee-profile :employee="toEmployee(row.item)" />
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">{{ row.item.branch || "-" }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{ row.item.unit || "-" }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title>{{ row.item.typeName }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{ stringifyLeaveDays(row.item) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td class="text-no-wrap text-right">
                    <template v-if="isApprover(row.item.approverId)">
                      <v-btn
                        :loading="processing && row.item.leaveRequestId === processedId && !isApproveProcess"
                        :disabled="processing && row.item.leaveRequestId !== processedId"
                        color="primary"
                        class="mr-2"
                        x-small
                        depressed
                        fab
                        @click="onClickLeaveApproval(row.item.leaveRequestId, false, row.index)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-btn
                        :loading="processing && row.item.leaveRequestId === processedId && isApproveProcess"
                        :disabled="processing && (row.item.leaveRequestId !== processedId || isApproveProcess)"
                        color="success"
                        x-small
                        depressed
                        fab
                        @click="onClickLeaveApproval(row.item.leaveRequestId, true, row.index)"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <v-card class="elevation-0 fill-height d-flex justify-center align-center">
                {{ $t("dashboard.messages.no_data_leaves") }}
              </v-card>
            </template>
          </template>
        </v-tab-item>
        <v-tab-item value="overtime" class="fill-height">
          <template v-if="loading">
            <v-card class="elevation-0 fill-height d-flex justify-center align-center">
              <v-progress-circular indeterminate size="60" color="grey lighten-3" />
            </v-card>
          </template>
          <template v-else>
            <v-data-table
              v-if="dashboardData.overtimeRequests.length"
              class="request-table"
              :items="dashboardData.overtimeRequests"
              :loading="loading"
              :no-data-text="loading ? '' : $vuetify.noDataText"
              :footer-props="{ itemsPerPageOptions: [5] }"
              hide-default-header
            >
              <template #item="row">
                <tr>
                  <td>
                    <employee-profile :employee="row.item.overtime.employee" />
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">
                        {{ (row.item.overtime.employee.employment.branch || {}).name || "-" }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption">
                        {{ (row.item.overtime.employee.employment.unit || {}).name || "-" }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title>{{ row.item.overtime.workplan.date | formattedDate }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">
                        {{ getOvertimeFormat(row.item.overtime.overtimeInMinutes) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td class="text-no-wrap text-right">
                    <template v-if="isApprover(row.item.approver.id)">
                      <v-btn
                        :loading="processing && row.item.id === processedId && !isApproveProcess"
                        :disabled="processing && (row.item.id !== processedId || isApproveProcess)"
                        color="primary"
                        class="mr-2"
                        x-small
                        depressed
                        fab
                        @click="onClickOvertimeApproval(row.item.id, false, row.index)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-btn
                        :loading="processing && row.item.id === processedId && isApproveProcess"
                        :disabled="processing && (row.item.id !== processedId || !isApproveProcess)"
                        color="success"
                        x-small
                        depressed
                        fab
                        @click="onClickOvertimeApproval(row.item.id, true, row.index)"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <v-card class="elevation-0 fill-height d-flex justify-center align-center">
                {{ $t("dashboard.messages.no_data_overtimes") }}
              </v-card>
            </template>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    name: "requests",
    props: {
      dashboardData: {
        type: Object,
        required: true
      },
      loading: Boolean
    },
    data: vm => ({
      tab: "leave",
      processedId: null,
      isApproveProcess: false,
      processing: false
    }),

    methods: {
      toEmployee(item) {
        return {
          firstName: item.firstName,
          lastName: item.lastName,
          profile: {
            imageUrl: item.imageUrl
          }
        };
      },
      isApprover(approverId) {
        return approverId === this.$store.state.auth.user.id;
      },
      onClickLeaveApproval(id, status, index) {
        if (id) {
          this.processedId = id;
          this.isApproveProcess = status;
          const payload = {
            approved: status,
            note: ""
          };
          this.processing = true;
          this.$api.leaveRequestService
            .approve(id, payload)
            .then(({ data }) => {
              if (!data.error) {
                this.dashboardData.leaveRequests.splice(index, 1);
              }
            })
            .catch(console.error)
            .finally(() => {
              this.processing = false;
              this.processedId = null;
            });
        }
      },
      onClickOvertimeApproval(id, status, index) {
        if (id) {
          this.processedId = id;
          this.isApproveProcess = status;
          const payload = {
            approved: status,
            note: ""
          };
          this.processing = true;
          this.$api.overtimeService
            .approve(id, payload)
            .then(({ data }) => {
              if (!data.error) {
                this.dashboardData.overtimeRequests.splice(index, 1);
              }
            })
            .catch(console.error)
            .finally(() => {
              this.processing = false;
              this.processedId = null;
            });
        }
      },
      getOvertimeFormat(overtime) {
        return overtime / 60 >= 1
          ? (overtime / 60).toFixed(1) + " " + this.$t("date.time")
          : overtime.toFixed(1) + " " + this.$t("date.minute");
      },
      stringifyLeaveDays(item) {
        return item.isHourly
          ? item.forHourly < 60
            ? `${item.forHourly} ${this.$t("date.minute")}`
            : `${(item.forHourly / 60).toFixed(2)} ${this.$t("date.time")}`
          : `${item.forDay} ${this.$t("date.day")}`;
      }
    }
  };
</script>

<style scoped lang="scss">
  .request-table::v-deep {
    .v-data-table__wrapper {
      min-height: 325px;
    }
  }
</style>
